<template>
  <section
    id="youtube-video-two"
    class="py-0"
  >
  <br>
  <br>
    <v-row
      class="mx-auto"
    >
      <v-col
        cols="0"
      >
      </v-col>
      <v-col
        cols="12"
      >
        <youtube
        :video-id="videoId"
        :player-vars="playerVars"
        width="100%"
        :height="$vuetify.breakpoint.smAndDown ? 200 : 500"
      ></youtube>
      </v-col>
      <v-col
        cols="0"
      >
      </v-col>
    </v-row>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionYoutubeVideoTwo',
    data () {
      return {
        videoId: 'tNfPLsmmeig',
        playerVars: {
          autoplay: 0,
        },
      }
    },
  }
</script>
